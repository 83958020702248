import {createBrowserRouter, Navigate, Outlet, RouterProvider, useRouteError} from "react-router-dom";
import GlobalStyle from "./globalStyles";
import styled from "styled-components";
import {
    Button,
    Content,
    ContentBody, ContentFooter,
    ContentHeader,
    Footer,
    Header,
    ScrollToTop,
    Text
} from "./components";
import {Home, UnsupportedBrowser, NoMatch, ChooseText, Intro, QuestionPage, End, ReadText} from "./routes";
import ExperimentProvider from "./contexts/ExperimentContext";
import AuthProvider, {useAuth} from "./contexts/AuthContext";

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const Layout = () => {
    return (
        <StyledLayout>
            <GlobalStyle />
            <ScrollToTop />

            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
        </StyledLayout>
    )
}

function App() {
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Layout />,
            errorElement: <ErrorBoundary />,
            children: [
                {
                    index: true,
                    element: (!isSafari ? <Home /> : <UnsupportedBrowser/>),
                },
                {
                    path: '/tekst-kiezen',
                    element: <RequireAuth><ChooseText /></RequireAuth>,
                },
                {
                    path: '/introductie',
                    element: <RequireAuth><Intro /></RequireAuth>,
                },
                {
                    path: '/tekst',
                    element: <RequireAuth><ReadText /></RequireAuth>,
                },
                {
                    path: '/vraag',
                    element: <RequireAuth><QuestionPage /></RequireAuth>,
                },
                {
                    path: '/einde',
                    element: <RequireAuth><End /></RequireAuth>,
                },
                {
                    path: "*",
                    element: <NoMatch />,
                }
            ],
        },
    ]);

    return (
        <AuthProvider>
            <ExperimentProvider>
                <RouterProvider router={router}/>
            </ExperimentProvider>
        </AuthProvider>
    );
}

function ErrorBoundary() {
    let error = useRouteError();
    console.error(error)

    return (
        <StyledLayout>
            <GlobalStyle />
            <ScrollToTop />

            <Header />
            <main>
                <Content>
                    <ContentHeader>Begrijpend lezen</ContentHeader>
                    <ContentBody>
                        <Text>Er is iets fout gegaan tijdens het experiment. Vraag de onderzoeker om hulp.</Text>
                    </ContentBody>
                    <ContentFooter>
                        <Button to="/" replace={true}>Terug naar begin</Button>
                    </ContentFooter>
                </Content>
            </main>
            <Footer />
        </StyledLayout>
    )
}

function RequireAuth({children}) {
    let auth = useAuth();

    if(!auth.codeID) {
        return <Navigate to='/' replace={true}/>
    }

    return children;
}


export default App;
