import styled from 'styled-components';

export const Title = styled.h1`
    color: var(--color-primary);
    font-family: var(--font-primary);
    font-size: 27px;
    font-weight: var(--fw-demi-bold);
    text-align: ${props => props.$center ? 'center' : 'left'};
`;

export const Heading = styled.h2`
    color: var(--color-primary);
    font-family: var(--font-primary);
    font-size: 20px;
    font-weight: var(--fw-demi-bold);
    line-height: 1.25;

    ${props => props.$align && `
        text-align: ${props.$align};
    `};
`;