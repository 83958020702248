import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Button = styled(Link)`
    background-color: var(--color-primary);
    color: var(--color-white);
    font-family: var(--font-primary);
    font-weight: var(--fw-bold);
    font-size: 16px;
    border-radius: 3px;
    padding: 6px 30px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    line-height: 1.5;
    border: none;
    cursor: pointer;
`;