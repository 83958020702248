import {Button, Content, ContentBody, ContentFooter, ContentHeader, Question, WarnBeforeUnload} from '../../components';
import {useExperimentData} from '../../contexts/ExperimentContext';
import {useNavigate} from 'react-router-dom';
import {useEffect, useRef, useState} from "react";
import {routeToNextScreen} from '../../utils/Routing';

export default function QuestionPage() {
    const navigate = useNavigate();

    // States to make values immutable
    const [startTimeQuestion, setStartTimeQuestion] = useState(null);
    const [startTimeFeedback, setStartTimeFeedback] = useState(null);
    const durationQuestion = useRef(null);

    const [answer, setAnswer] = useState();
    const [validate, setValidate] = useState(false);
    const [feedbackShown, setFeedbackShown] = useState(false);

    const { fetchedData, currentText, currentType, setCurrentType, currentTypeNumber, setCurrentTypeNumber, addType, result } = useExperimentData()

    useEffect(() => {
        setStartTimeQuestion(Date.now())
    }, [currentTypeNumber])

    const handleButtonClick = () => {
        if (answer !== undefined) {
            // If there is no startTimeFeedback yet (2nd btn click), calculate the questionDuration
            if (!startTimeFeedback) {
                let questionDuration = (Date.now() - startTimeQuestion) / 1000;
                durationQuestion.current = Math.round(questionDuration);
            }

            if (currentType.questionType !== 'none' && feedbackShown === false) {
                setStartTimeFeedback(Date.now())
                setFeedbackShown(true);
                setValidate(true);
                return;
            }

            let defaultFields = {
                "typeId": currentType.id,
                "questionDuration": durationQuestion.current.toString(),
                "optionId": answer,
            };

            // If startTimeFeedback exists (only exists when questionType !== none
            if (startTimeFeedback) {
                let feedbackDuration = Math.round((Date.now() - startTimeFeedback) / 1000);
                defaultFields.feedbackDuration = feedbackDuration.toString();
            }

            addType(defaultFields);

            // Clear state
            setStartTimeQuestion(null);
            setStartTimeFeedback(null);
            setAnswer(undefined);
            setValidate(false);
            setFeedbackShown(false);

            // Route to next screen
            routeToNextScreen(navigate, currentText, setCurrentType, currentTypeNumber, setCurrentTypeNumber, fetchedData.codeId, result);
        }
    }

    return (
        <Content>
            <ContentHeader>{currentText.title}</ContentHeader>
            <ContentBody>
                <Question
                    data={currentType}
                    validate={validate}
                    answer={answer}
                    setAnswer={setAnswer}
                />
            </ContentBody>
            <ContentFooter $align="right">
                <Button as="button" onClick={() => handleButtonClick()}>Volgende</Button>
            </ContentFooter>
            <WarnBeforeUnload />
        </Content>
    );
}