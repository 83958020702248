import {Button, Content, ContentBody, ContentFooter, ContentHeader, WarnBeforeUnload} from '../../components';
import {useExperimentData} from '../../contexts/ExperimentContext';
import {HtmlText} from '../../components/HtmlText/HtmlText';
import {useNavigate} from 'react-router-dom';
import {routeToNextScreen} from '../../utils/Routing';

export default function ReadText() {
    // Get the startTime when ReadText renders
    const startTime = Date.now()

    const { fetchedData, currentText, setCurrentType, currentType, currentTypeNumber, setCurrentTypeNumber, addType, result } = useExperimentData()

    const navigate = useNavigate();

    const handleButtonClick = () => {
        // Get endTime on click and calculate the difference from the startTime in seconds
        const endTime = Date.now();
        const textDuration = Math.round((endTime - startTime) / 1000);

        let defaultFields = {
            "typeId": currentType.id,
            "textDuration": textDuration.toString()
        };

        addType(defaultFields)

        // Route to next screen
        routeToNextScreen(navigate, currentText, setCurrentType, currentTypeNumber, setCurrentTypeNumber, fetchedData.codeId, result);
    }
    return (
        <Content>
            <ContentHeader>{currentText.title}</ContentHeader>
            <ContentBody>
                <HtmlText dangerouslySetInnerHTML={{ __html: currentType.text }} />
            </ContentBody>
            <ContentFooter $align="right">
                <Button as="button" onClick={() => handleButtonClick()}>Volgende</Button>
            </ContentFooter>
            <WarnBeforeUnload />
        </Content>
    );
}