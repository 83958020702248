import React, { useRef, useLayoutEffect } from "react";
import usePrevious from "../../hooks/usePrevious";
import styled from 'styled-components';

const Input = styled.input`
    background: transparent;
    outline: none;
    border: none;
    color: var(--color-primary);
    font-family: var(--font-primary);
    font-weight: var(--fw-bold);
    font-size: 24px;
    text-align: center;
    padding: 14px 0;
    width: 40px;
    
    -moz-appearance: textfield; //Hide spinners on Firefox
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }
    
    &:disabled {
        -webkit-opacity: 1; // Override Safari font color change
        opacity: 1;
        color: #D3D3CC;
        -webkit-text-fill-color: #D3D3CC;
    }
`;

const Field = styled.div`
    display: inline-block;
    position: relative;
    opacity: ${props => props.$disabled ? '0.5' : '1'};

    &:not(:last-of-type) {
        margin-right: 18px;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background-color: #D3D3CC;
        border-radius: 3px;
        transition: background-color .2s ease;
    }

    &.active {
        &::after {
            background-color: var(--color-accent);
        } 
    }
`;


const CodeInput = ({focus, autoFocus, isEmpty, ...props}) => {
    const ref = useRef(null);
    const prevFocus = usePrevious(!!focus);

    useLayoutEffect(() => {
        if (ref.current) {
            if (focus && autoFocus) {
                ref.current.focus();
            }

            if (focus && autoFocus && focus !== prevFocus) {
                ref.current.focus();
                ref.current.select();
            }
        }
    }, [autoFocus, focus, prevFocus]);

    return (
        <Field className={focus || isEmpty.length > 0 ? 'active' : 'none'}>
            <Input ref={ref} type="number" {...props} />
        </Field>
    );
}

export default CodeInput;
