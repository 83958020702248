import {Button, Content, ContentBody, ContentFooter, ContentHeader, Text} from '../../components';

export default function NoMatch() {
    return (
        <Content>
            <ContentHeader>404 - Deze pagina kon niet gevonden worden</ContentHeader>
            <ContentBody>
                <Text>Het lijkt erop dat deze pagina niet bestaat.</Text>
            </ContentBody>
            <ContentFooter $align="right">
                <Button to="/">Ga terug naar het begin</Button>
            </ContentFooter>
        </Content>
    );
}