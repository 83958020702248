import styled from 'styled-components';
import {Title} from '../Heading/Heading';

const Header = styled.header`
    padding: 30px 40px;
    background: linear-gradient(180deg, #F7FDFF 0%, #EEEEEE 100%);
    border-bottom: 1px solid #DDDDDD;
`;

const ContentHeader = ({children, ...props}) => {
    return (
            <Header>
                <Title {...props}>{children}</Title>
            </Header>
    )
}

export default ContentHeader
