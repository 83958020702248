import {Content, ContentBody, ContentHeader, Text} from '../../components';

export default function UnsupportedBrowser() {
    return (
        <Content>
            <ContentHeader>Uw browser wordt niet ondersteund</ContentHeader>
            <ContentBody>
                <Text>Om gebruik te kunnen maken van de applicatie dient u gebruik te maken van Google Chrome of Firefox. Open de toepassing in één van deze browsers en probeer opnieuw.</Text>
            </ContentBody>
        </Content>
    );
}