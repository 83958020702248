import styled from 'styled-components';
import {Text} from '../index';

export const Code = styled.section`
    padding: ${props => props.$error ? '86px 0 30px' : '86px 0'};
    margin-top: auto;
    background-color: #F2F2F0;
    border-top: 5px solid #D3D3CC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ErrorText = styled(Text)`
    color: #DC492F;
    margin: 32px 0;
`;