import {
    Button,
    Content,
    ContentBody,
    ContentFooter,
    ContentHeader,
    Grid,
    Heading,
    Text,
    WarnBeforeUnload
} from '../../components';
import {useExperimentData} from '../../contexts/ExperimentContext';

export default function ChooseText() {

    const { fetchedData } = useExperimentData();

    return (
        <Content>
            <ContentHeader>Begrijpend lezen</ContentHeader>
            <ContentBody $noFooter>
                {fetchedData.texts.length > 0 ?
                    <>
                        <Heading>Laten we beginnen met een tekst om te lezen!</Heading>
                        <Grid data={fetchedData.texts}/>
                    </>
                :
                    <>
                        <Heading $align="center">Er zijn geen teksten beschikbaar voor deze code</Heading>
                        <Text $align="center">Vraag de onderzoeker om hulp</Text>
                    </>
                }
            </ContentBody>
            <ContentFooter $align="center">
                <Button to="/" replace={true}>Vul opnieuw een code in</Button>
            </ContentFooter>
            <WarnBeforeUnload />
        </Content>
    );
}