import {Button, Content, ContentBody, ContentFooter, ContentHeader, WarnBeforeUnload} from '../../components';
import {ContentColumn, ContentImage} from '../../components/Content/ContentUtils';
import {useExperimentData} from '../../contexts/ExperimentContext';
import {HtmlText} from '../../components/HtmlText/HtmlText';
import {useNavigate} from 'react-router-dom';
import {routeToNextScreen} from '../../utils/Routing';

export default function Intro() {

    const { fetchedData, currentText, setCurrentType, currentTypeNumber, setCurrentTypeNumber, addMainInfo } = useExperimentData()

    const navigate = useNavigate();

    const handleButtonClick = () => {

        addMainInfo({
            id: fetchedData.codeId,
            startTime: Math.floor(Date.now() / 1000).toString(),
            codeTextId: currentText.codeTextId,
        })

        // Route to next screen
        routeToNextScreen(navigate, currentText, setCurrentType, currentTypeNumber, setCurrentTypeNumber);
    }

    return (
        <Content>
            <ContentHeader>{currentText.title}</ContentHeader>
            <ContentBody $row>
                <ContentColumn>
                    <ContentImage src={currentText.imageUrl} alt={currentText.title}/>
                </ContentColumn>
                <ContentColumn>
                    <HtmlText dangerouslySetInnerHTML={{ __html: currentText.explanationText }} />
                </ContentColumn>
            </ContentBody>
            <ContentFooter $align="right">
                <Button as="button" onClick={() => handleButtonClick()}>Start met lezen</Button>
            </ContentFooter>
            <WarnBeforeUnload />
        </Content>
    );
}