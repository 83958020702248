import {useEffect} from "react";

const WarnBeforeUnload = () => {
    // Before user unloads the page, give a warning
    const beforeUnloadListener = (e) => {
        e.preventDefault();
        return e.returnValue = "Je staat op het punt om het experiment af te sluiten. Alle ingevulde data zal verloren gaan. Weet je zeker dat je het experiment wil stoppen?"
    };

    useEffect(() => {
        window.addEventListener('beforeunload', beforeUnloadListener, {capture: true})

        return () => window.removeEventListener('beforeunload', beforeUnloadListener, {capture: true})
    }, [])

    return null;
}

export default WarnBeforeUnload