import styled from 'styled-components';
import {Heading} from '../Heading/Heading';
import {Text} from '../index';
import radio from "../../assets/radio.svg";
import radioCorrect from "../../assets/radio-correct.svg";
import radioWrong from "../../assets/radio-wrong.svg";

const StyledQuestion = styled.section`
    padding: 15px 20px 20px;
    background-color: #F2F2F0;
    border-top: 5px solid #D3D3CC;
    overflow-y: auto; // To have scrollbar inside StyledQuestion
`;

const QuestionAnswer = styled.label`
    display: block;
    cursor: pointer;
    padding: 13px 15px;
    background-color: var(--color-white);
    border: 1px solid #EBEBEB;
    margin-top: 8px;
    
    &:first-of-type {
        margin-top: 20px;
    }

    &.correct {
        background-color: #D6E6D0;
        border: 1px solid #C5D4BF;
    }
    
    &.wrong {
        background-color: #F0D3CD;
        border: 1px solid #DDC2BD;
    }
`;

const Box = styled.div`
    display: flex;
    align-items: center;
`;

const Label = styled(Text).attrs(() => ({
    as: 'label'
}))`
    cursor: pointer;
`;

const Input = styled.input`
    cursor: pointer;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid #E1E1E1;
    appearance: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 13px 0 0;

    &:checked {
        border: 0;
        background-image: ${props => props.$result === 'correct' ? `url(${radioCorrect})` : props.$result === 'wrong' ? `url(${radioWrong})` : `url(${radio})`};
    }
    
    &.correct {
        border: 0;
        background-image: url(${radioCorrect});
    }
`;

const Feedback = styled(Text)`
    margin-top: 5px;
    font-style: italic;
`;

const Question = ({data, validate, answer, setAnswer}) => {

    let correctOption = data.options.find(option => option.correct === 1);

    return (
        <StyledQuestion>
            <Heading>{data.question}</Heading>
            {data.options.map((item) => (
                <QuestionAnswer
                    className={(validate && (item.correct === 1 ? 'correct' : (answer === item.id && (answer !== correctOption?.id ? 'wrong' : ''))))}
                    key={item.id}
                    htmlFor={item.id}
                >
                    <Box>
                        <Input
                            className={(validate && (item.correct === 1 ? 'correct' : ''))}
                            type="radio"
                            id={item.id}
                            name={data.id}
                            value={item.id}
                            onClick={() => setAnswer(item.id)}
                            disabled={!!validate}
                            $result={(validate && (item.correct === 1 ? 'correct' : 'wrong'))}
                        />
                        <Label htmlFor={item.id}>
                            {item.title}
                        </Label>
                    </Box>
                    {data.questionType === 'extensive' && (validate && (item.correct === 1 &&
                        <Feedback>
                            {data.feedback}
                        </Feedback>
                    ))}
                </QuestionAnswer>
            ))}
        </StyledQuestion>
    )
}

export default Question