import {createContext, useContext, useState} from "react";

export const AuthContext = createContext(null);

export default function AuthProvider({children}) {
    let [codeID, setCodeID] = useState(null);

    let updateCode = (newCodeID) => {
        return setCodeID(newCodeID);
    }

    let authenticateCodeAndNavigate = (newCodeID, callback) => {
        return codeIDAuthProvider.authenticateCodeAndNavigate(() => {
            setCodeID(newCodeID);
            callback();
        });
    };

    let invalidateCode = () => {
        return setCodeID(null);
    }

    let value = { codeID, updateCode, authenticateCodeAndNavigate, invalidateCode };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);

const codeIDAuthProvider = {
    isAuthenticated: false,
    authenticateCodeAndNavigate(callback) {
        codeIDAuthProvider.isAuthenticated = true;
        setTimeout(callback, 100);
    },
};

export { codeIDAuthProvider };