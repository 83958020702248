import styled from 'styled-components';

export const Text = styled.p`
    color: var(--color-text);
    font-family: var(--font-secondary);
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 30px;
    ${props => props.$align && ` text-align: ${props.$align}; `};
    
    &:last-of-type {
        margin-bottom: 0;
    }
`;