import styled from 'styled-components';
import {Container} from '../Container/Container';

const StyledContent = styled.article`
    margin-top: -50px;
    padding-bottom: 25px;
`;

const Card = styled.div`
    background-color: var(--color-content-background);
    box-shadow: 0 0 5px rgba(120, 110, 100, 0.4);
    height: 650px;
    display: flex;
    flex-direction: column;
`;

const Content = ({children}) => {
    return (
        <StyledContent>
            <Container>
                <Card>
                    {children}
                </Card>
            </Container>
        </StyledContent>
    )
}

export default Content