import styled, {keyframes} from 'styled-components';

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const spinner = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div`
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
`;

const LoadPart = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid var(--color-primary);
    border-radius: 50%;
    animation: ${spinner} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color-primary) transparent transparent transparent;
    
    &:nth-child(1) {
        animation-delay: -0.45s;
    }

    &:nth-child(2) {
        animation-delay: -0.3s;
    }

    &:nth-child(3) {
        animation-delay: -0.15s;
    }
`;

export const Loader = () => {

    return (
        <Center>
            <StyledLoader>
                <LoadPart></LoadPart>
                <LoadPart></LoadPart>
                <LoadPart></LoadPart>
                <LoadPart></LoadPart>
            </StyledLoader>
        </Center>
    );
}