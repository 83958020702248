import { createGlobalStyle } from 'styled-components';

import VestulaProRegularWoff from './assets/fonts/VestulaPro-Regular.woff';
import VestulaProRegularWoff2 from './assets/fonts/VestulaPro-Regular.woff2';
import VestulaProDemiBoldWoff from './assets/fonts/VestulaPro-Bold.woff';
import VestulaProDemiBoldWoff2 from './assets/fonts/VestulaPro-Bold.woff2';
import VestulaProBoldWoff from './assets/fonts/VestulaPro-DemiBold.woff';
import VestulaProBoldWoff2 from './assets/fonts/VestulaPro-DemiBold.woff2';
import VestulaProItalicWoff from './assets/fonts/VestulaPro-Italic.woff';
import VestulaProItalicWoff2 from './assets/fonts/VestulaPro-Italic.woff2';

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    /* CSS Properties */
    :root {
        /* Base colors */
        --color-background: #f5f5f5;
        --color-white: #fff;
        --color-content-background: #fafafa;

        /* Brand colors */
        --color-primary: #001158;
        --color-secondary: #666;
        --color-accent: #b27f2b;

        /* Text colors */
        --color-text: #333;
        --color-footer-text: #777;
        
        /* Font */
        --font-primary: "VestulaPro", serif;
        --font-secondary: "Georgia", serif;
        
        --fw-bold: 700;
        --fw-demi-bold: 600;
        --fw-normal: 400;
    }
    
    /* Custom fonts */
    @font-face {
        font-family: 'VestulaPro';
        src: local('VestulaPro-Regular'), url(${VestulaProRegularWoff2}) format('woff2'), url(${VestulaProRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'VestulaPro';
        src: local('VestulaPro-DemiBold'), url(${VestulaProDemiBoldWoff2}) format('woff2'), url(${VestulaProDemiBoldWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'VestulaPro';
        src: local('VestulaPro-Bold'), url(${VestulaProBoldWoff2}) format('woff2'), url(${VestulaProBoldWoff}) format('woff');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'VestulaPro';
        src: local('VestulaPro-Italic'), url(${VestulaProItalicWoff2}) format('woff2'), url(${VestulaProItalicWoff}) format('woff');
        font-weight: normal;
        font-style: italic;
    }

    /* http://meyerweb.com/eric/tools/css/reset/ 
       v2.0 | 20110126
       License: none (public domain)
    */

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }

    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    body {
        line-height: 1;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    
    html, body {
        background-color: var(--color-background);
    }
`;

export default GlobalStyle;