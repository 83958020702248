export const routeToNextScreen = (navigate, currentText, setCurrentType, currentTypeNumber, setCurrentTypeNumber, codeId, result) => {

    if(currentTypeNumber >= currentText.types.length) {
        return navigate('/einde', { replace: true });
    }

    setCurrentTypeNumber(currentTypeNumber + 1)
    setCurrentType(currentText.types[currentTypeNumber]);

    return currentText.types[currentTypeNumber].type === 'text' ? navigate('/tekst', { replace: true }) : navigate('/vraag', { replace: true })
}