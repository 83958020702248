import styled from 'styled-components';

const Footer = styled.footer`
    margin-top: auto;
    padding: 20px 40px 40px;

    ${props => props.$align && `
        text-align: ${props.$align};
    `};
`;

const ContentFooter = ({children, ...props}) => {
    return (
            <Footer {...props}>
                {children}
            </Footer>
    )
}

export default ContentFooter