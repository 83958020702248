import React, { useState, useCallback } from 'react';
import {CodeInput} from "../index";
import styled from "styled-components";

const StyledCodeInputFields = styled.div`
    margin-top: 10px;
`;

const CodeInputFields = ({disabled, onCodeChange, ...props}) => {
    const length = 5;

    const [activeInput, setActiveInput] = useState(0);
    const [codeValues, setCodeValues] = useState(Array(length).fill(''));

    // Return the code from the input fields
    const handleCodeChange = useCallback(
        (code) => {
            const codeValue = code.join('');
            onCodeChange(codeValue);
        },
        [onCodeChange],
    );

    // Return the correct value
    const getRightValue = useCallback(
        (input) => {
            let changedValue = input;

            return Number(changedValue) >= 0 ? changedValue : '';
        },
        [],
    );

    // Change code input value at focussing input
    const changeCodeAtFocus = useCallback(
        (str) => {
            const updatedCodeValues = [...codeValues];
            updatedCodeValues[activeInput] = str[0] || '';
            setCodeValues(updatedCodeValues);
            handleCodeChange(updatedCodeValues);
        },
        [activeInput, handleCodeChange, codeValues],
    );

    // Focus `inputIndex` input
    const focusInput = useCallback(
        (inputIndex) => {
            const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
            setActiveInput(selectedIndex);
        },
        [length],
    );

    const focusPrevInput = useCallback(() => {
        focusInput(activeInput - 1);
    }, [activeInput, focusInput]);

    const focusNextInput = useCallback(() => {
        focusInput(activeInput + 1);
    }, [activeInput, focusInput]);

    // Handle onFocus input
    const handleOnFocus = useCallback(
        (index) => () => {
            focusInput(index);
        },
        [focusInput],
    );

    // Handle onChange value for each input
    const handleOnChange = useCallback(
        (e) => {
            const val = getRightValue(e.currentTarget.value);
            if (!val) {
                e.preventDefault();
                return;
            }
            changeCodeAtFocus(val);
            focusNextInput();
        },
        [changeCodeAtFocus, focusNextInput, getRightValue],
    );

    // Handle onBlur input
    const onBlur = useCallback(() => {
        setActiveInput(-1);
    }, []);

    // Handle onKeyDown input
    const handleOnKeyDown = useCallback(
        (e) => {
            const pressedKey = e.key;

            // Prevent number input to enter 'e', '-', '+' etc.
            // if (!pressedKey.match(/[0-9]/)) { e.preventDefault() }

            switch (pressedKey) {
                case 'Backspace':
                case 'Delete': {
                    e.preventDefault();
                    if (codeValues[activeInput]) {
                        changeCodeAtFocus('');
                    } else {
                        focusPrevInput();
                    }
                    break;
                }
                case 'ArrowLeft': {
                    e.preventDefault();
                    focusPrevInput();
                    break;
                }
                case 'ArrowRight': {
                    e.preventDefault();
                    focusNextInput();
                    break;
                }
                case `'`:
                case 'E':
                case 'e': {
                    e.preventDefault();
                    break;
                }
                default: {
                    if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
                        e.preventDefault();
                    }

                    break;
                }
            }
        },
        [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, codeValues],
    );

    const handleOnPaste = useCallback(
        (e) => {
            e.preventDefault();
            const pastedData = e.clipboardData
                .getData('text/plain')
                .trim()
                .slice(0, length - activeInput)
                .split('');
            if (pastedData) {
                let nextFocusIndex = 0;
                const updatedCodeValues = [...codeValues];
                updatedCodeValues.forEach((val, index) => {
                    if (index >= activeInput) {
                        const changedValue = getRightValue(pastedData.shift() || val);
                        if (changedValue) {
                            updatedCodeValues[index] = changedValue;
                            nextFocusIndex = index;
                        }
                    }
                });
                setCodeValues(updatedCodeValues);
                setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
                handleCodeChange(updatedCodeValues);
            }
        },
        [activeInput, getRightValue, length, codeValues, handleCodeChange],
    );

    return (
        <StyledCodeInputFields {...props}>
            {Array(length).fill('').map((_, index) => (
                <CodeInput
                    key={`SingleInput-${index}`}
                    focus={activeInput === index}
                    value={codeValues && codeValues[index]}
                    onFocus={handleOnFocus(index)}
                    onChange={handleOnChange}
                    onKeyDown={handleOnKeyDown}
                    onBlur={onBlur}
                    onPaste={handleOnPaste}
                    isEmpty={codeValues && codeValues[index]}
                    autoFocus
                    disabled={disabled}
                />
            ))}
        </StyledCodeInputFields>
    );
}

export default CodeInputFields;