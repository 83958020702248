import styled from 'styled-components';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as Cross } from '../../assets/cross.svg';
import background from '../../assets/background.jpg';
import {Link, useLocation} from 'react-router-dom';
import {Container} from '../Container/Container';

const NavBar = styled.nav`
    background-color: var(--color-white);
    padding: 25px 0;
`;

const FlexContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Box = styled.div`
    flex: 1 1 33%;
`;

const StyledLogo = styled(Logo)`
    display: block;
    margin: 0 auto;
    width: 151px;
    height: 64px;
`;

const Background = styled.div`
    background-image: url(${background});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100px;
`;

const StyledLink = styled(Link)`
    display: inline-block;
`;

const pagesWithoutCross = ['/'];

const Header = () => {
    let { pathname } = useLocation();

    return (
        <header>
            <NavBar>
                <FlexContainer>
                    <Box>
                        {!pagesWithoutCross.includes(pathname) && <StyledLink to="/" reloadDocument><Cross /></StyledLink>}
                    </Box>
                    <Box>
                        <Link to="/" reloadDocument><StyledLogo /></Link>
                    </Box>
                    <Box />
                </FlexContainer>
            </NavBar>
            <Background />
        </header>
    )
}

export default Header