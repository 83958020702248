import styled from 'styled-components';

export const ContentGif = styled.img.attrs(() => ({
    alt: 'Eindscherm gif afbeelding'
}))`
    max-width: 600px;
    width: 100%;
    height: auto;
    margin: auto;
`;

export const ContentColumn = styled.div`
    flex: 1;
`;

export const ContentImage = styled.img`
    width: 100%;
    height: auto;
`;