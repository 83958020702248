import styled from 'styled-components';

export const HtmlText = styled.div`
    
    p {
        // Text Styles
        color: var(--color-text);
        font-family: var(--font-secondary);
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 30px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
    
    h3 {
        // Heading Styles
        color: var(--color-primary);
        font-family: var(--font-primary);
        font-size: 20px;
        font-weight: var(--fw-demi-bold);
        line-height: 1.25;
    }
    
    img {
        width: 100%;
        max-width: 300px;
        object-fit: cover;
        height: auto;
    }
    
    strong { font-weight: var(--fw-bold); }
    em { font-style: italic; }
    ul { list-style-type: disc; }
    ol { list-style-type: decimal; }
    ol, ul {
        list-style-position: inside;
        margin-bottom: 30px;
    }
    
    li {
        color: var(--color-text);
        font-family: var(--font-secondary);
        font-size: 16px;
        line-height: 1.5;
    }
`;