import styled from 'styled-components';
import {Heading} from '../Heading/Heading';
import {Button} from '../Button/Button';
import { ReactComponent as Check } from '../../assets/check.svg';
import {useExperimentData} from '../../contexts/ExperimentContext';
import {useEffect} from 'react';

const StyledGrid = styled.section`
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    
    @media screen and (max-width: 840px){
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 615px){
        grid-template-columns: repeat(1, 1fr);
    }
`;

const Item = styled.div`
    background-color: #F5F5F4;
    position: relative;
    display: flex;
    flex-direction: column;
`;

const Figure = styled.figure`
    position: relative;
    width: 100%;

    @supports not (aspect-ratio: 16 / 9) {
        padding-top: 56.25%;
    }
`;

const Image = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 16 / 9;

    @supports not (aspect-ratio: 16 / 9) {
        position: absolute;
        top: 0;
    }
`;

const Body = styled.div`
    padding: 15px;
    min-height: 154px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

const Status = styled.div`
    align-self: ${props => props.$status ? 'center' : 'flex-end'};
    margin-top: 15px;
`;

const Overlay = styled.div`
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
`;

const Grid = ({data}) => {

    const { setCurrentText, currentTextNumber, setCurrentTextNumber } = useExperimentData();

    useEffect(() => {
        data.map((item, index) => {
           return item.answered && setCurrentTextNumber(index + 1);
        })
    }, [data, setCurrentTextNumber])

    return (
            <StyledGrid>
                {data.map((item, index) => (
                    <Item key={index}>
                        <Figure>
                            <Image src={item.imageUrl} alt={item.title} />
                        </Figure>
                        <Body>
                            <Heading>{index + 1}. {item.title}</Heading>
                            <Status $status={!!item.answered}>
                                {(item.answered === 0 && currentTextNumber === index) && <Button to="/introductie" replace={true} onClick={() => {setCurrentText(item); setCurrentTextNumber(index)}}>Begin met lezen</Button> }
                                {item.answered === 1 && <Check /> }
                            </Status>
                        </Body>
                        {(item.answered === 0 && index !== currentTextNumber) && <Overlay /> }
                    </Item>
                ))}
            </StyledGrid>
    )
}

export default Grid