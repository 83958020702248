import {createContext, useContext, useState} from "react";

export const ExperimentContext = createContext(null);

export default function ExperimentProvider({children}) {
    const [fetchedData, setFetchedData] = useState(null); // All fetched data when entering a code
    const [currentText, setCurrentText] = useState(null); // The data from the selected text
    const [currentTextNumber, setCurrentTextNumber] = useState(0); // An index number of the selected text
    const [currentType, setCurrentType] = useState(0); // The data from the current type
    const [currentTypeNumber, setCurrentTypeNumber] = useState(0); // An index number of the current type

    const [result, setResult] = useState({
        id: 0,
        replies: [
            {
                startTime: 0,
                codeTextId: 0,
                types: [],
            }
        ]
    })

    // console.log(JSON.stringify(result, null, 2))

    const addMainInfo = (data) => {
        setResult({
            ...result,
            id: data.id,
            replies: [
                {
                    ...result.replies[0],
                    startTime: data.startTime,
                    codeTextId: data.codeTextId,
                }
            ]
        });
    }

    const addType = (data) => {
        setResult({
            ...result,
            replies: [
                {
                    ...result.replies[0],
                    types: [
                        ...result.replies[0].types,
                        data
                    ]
                }
            ]
        });
    }

    const clearExperimentContext = () => {
        setFetchedData(null)
        setCurrentText(null)
        setCurrentTextNumber(0)
        setCurrentType(0)
        setCurrentTypeNumber(0)
        setResult({
            id: 0,
            replies: [
                {
                    startTime: 0,
                    codeTextId: 0,
                    types: [],
                }
            ]
        })
    }

    return (
        <ExperimentContext.Provider value={{
            fetchedData,
            setFetchedData,
            currentText,
            setCurrentText,
            currentTextNumber,
            setCurrentTextNumber,
            currentType,
            setCurrentType,
            currentTypeNumber,
            setCurrentTypeNumber,
            result,
            setResult,
            addMainInfo,
            addType,
            clearExperimentContext
        }}>
            {children}
        </ExperimentContext.Provider>
    )
}

export const useExperimentData = () => useContext(ExperimentContext);