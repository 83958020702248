import {CodeInputFields, Content, ContentBody, ContentHeader, Heading, Text} from '../../components';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useExperimentData} from '../../contexts/ExperimentContext';
import {Code, ErrorText} from '../../components/Code/Code';
import {useAuth} from '../../contexts/AuthContext';

export default function Home() {

    const auth = useAuth();

    useEffect(() => {
        clearExperimentContext();
        auth.invalidateCode();
        // eslint-disable-next-line
    }, [])

    const navigate = useNavigate();
    const { clearExperimentContext, setFetchedData } = useExperimentData();

    const [isLoading, setIsLoading] = useState(false);
    const [codeError, setCodeError] = useState(null);

    function handleCode(code) {
        if (code.length < 5) { return }

        setIsLoading(true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/code?ID=${code}`, {
            method: 'get'
        }).then(async res => {
            const data = await res.json();

            if (codeError !== null) { setCodeError(null) } // If there was an error, reset the error

            if(!res.ok) {
                const err = (data && data.message) || res.statusText;

                setIsLoading(false)
                setCodeError("De code die is ingevoerd is onbekend bij ons. Probeer het opnieuw.");

                return Promise.reject(err);
            }

            setFetchedData(data);

            // Update code separately first otherwise there is no code in the route protector
            auth.updateCode(code);
            auth.authenticateCodeAndNavigate(code, () => navigate('/tekst-kiezen', { replace: true }));
        }).catch(err => {
            if(err !== 'Not Found') {
                setIsLoading(false)
                setCodeError("Het is niet gelukt om de code op te halen. Probeer het opnieuw.");
            }
        })
    }

    return (
        <Content>
            <ContentHeader>Begrijpend lezen</ContentHeader>
            <ContentBody $noFooter>
                <Text>Welkom bij deze leesoefening en fijn dat je meedoet! Om te kunnen beginnen, vul je jouw eigen inlogcode (van vijf cijfers) in. Deze heb je gehad van jouw leerkracht of de onderzoeker. Nadat je de code hebt ingevuld kan je met de leesoefening beginnen. Lukt het niet om in te loggen? Vraag dan de onderzoeker even om hulp.</Text>

                <Code $error={codeError}>
                    <Heading $align="center">Vul hier jouw code in</Heading>

                    <CodeInputFields onCodeChange={(code) => handleCode(code)} disabled={isLoading} />

                    {codeError && <ErrorText>{codeError}</ErrorText>}
                </Code>
            </ContentBody>
        </Content>
    );
}