import styled from 'styled-components';

const StyledBody = styled.div`
    padding: ${props => props.$noFooter ? '40px' : '40px 40px 0'};
    height: 100%;
    overflow: hidden;
`;

const Scroll = styled.div`
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: ${props => props.$row ? 'row' : 'column'};

    ${props => props.$row && `
        gap: 40px;
        
        @media screen and (max-width: 615px) {
            flex-direction: column;
        }
    `}
`;

const ContentBody = ({children, ...props}) => {
    return (
            <StyledBody {...props}>
                <Scroll {...props}>
                    {children}
                </Scroll>
            </StyledBody>
    )
}

export default ContentBody