import styled from 'styled-components';
import {useLocation} from 'react-router-dom';

const StyledFooter = styled.footer`
    margin-top: auto;
    padding: 45px 0;
    text-align: center;
`;

const StyledNavLink = styled.a`
    font-family: "VestulaPro", serif;
    font-size: 14px;
    color: var(--color-footer-text);
    text-decoration: none;
    
    &.active {
        text-decoration: underline;
    }
`;

const pagesWithLink = ['/', '/tekst-kiezen'];

const Footer = () => {
    let { pathname } = useLocation();

    return (
        <StyledFooter>
            {pagesWithLink.includes(pathname) && <StyledNavLink href="https://nlzoomaprdstorage.blob.core.windows.net/begrijpendlezen-production-silverstripe-assets-public/Uploads/221212_Privacyverklaring.pdf" target="_blank" rel="noreferrer">Privacyverklaring</StyledNavLink> }
        </StyledFooter>
    )
}

export default Footer