import {Button, Content, ContentBody, ContentFooter, ContentHeader, Text, WarnBeforeUnload} from '../../components';
import {ContentGif} from '../../components/Content/ContentUtils';
import {useExperimentData} from '../../contexts/ExperimentContext';
import axios from 'axios';
import {useEffect, useState} from 'react';
import GifOne from '../../assets/end/gif-one.webp';
import GifTwo from '../../assets/end/gif-two.webp';
import GifThree from '../../assets/end/gif-three.webp';
import GifFour from '../../assets/end/gif-four.webp';
import {Loader} from '../../components/Loader/Loader';

const gifs = [
    GifOne,
    GifTwo,
    GifThree,
    GifFour,
]

export default function End() {

    const { currentTextNumber, fetchedData, result } = useExperimentData()

    const [isLoading, setIsLoading] = useState(null);

    const [ status, setStatus ] = useState('loading');

    const putResultData = () => {
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/codes/${fetchedData.codeId}/replies`, result).then(response => {
            if (response.status !== 200) {
                console.error('Something went wrong, data not posted');
                setStatus('error');
            }

            console.log('Successfully posted data')
            setStatus('success');
            setIsLoading(false)

        }).catch(error => {
            console.error(error);
            setStatus('error');
        });
    }

    useEffect(() => {
        setIsLoading(true);
        setStatus('loading');

        putResultData();
        // eslint-disable-next-line
    }, [fetchedData.codeId, result])

    if(status === 'loading') {
        return (
            <Content>
                <ContentBody>
                    <Loader />
                </ContentBody>
            </Content>
        )
    }

    if(status === 'error') {
        return (
            <Content>
                <ContentHeader $center>Er is iets fout gegaan.</ContentHeader>
                <ContentBody>
                    <Text $align="center">Het is niet gelukt om de antwoorden op te slaan, probeer het opnieuw of vraag de onderzoeker om hulp.</Text>
                </ContentBody>
                <ContentFooter $align="center">
                    <Button as="button" onClick={putResultData}>Probeer opnieuw</Button>
                </ContentFooter>
                {isLoading && <WarnBeforeUnload />}
            </Content>
        )
    }

    return (
        <Content>
            <ContentHeader $center>Je bent klaar, goed gedaan!</ContentHeader>
            <ContentBody>
                <ContentGif src={gifs[currentTextNumber] ? gifs[currentTextNumber] : gifs[0]}/>
            </ContentBody>
            <ContentFooter $align="center">
                <Button to="/" replace={true}>Terug naar begin</Button>
            </ContentFooter>
            {isLoading && <WarnBeforeUnload />}
        </Content>
    );
}